import React from "react";
import axios from "../../../../axios";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import _ from "lodash";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.jsx";
import { array } from "prop-types";
import validation from "./validation";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { convertCompilerOptionsFromJson } from "typescript";

import AxisImage from "./axisImage";
import { connect } from "react-redux";
import { setSensorTypeId } from "../../../../redux/actions/sensorTypes.action";

const { SearchBar } = Search;

let levels = {
  rul: 2,
  sensorDataProcessConfigs: 2,
  algorithmPreference: 1,
  powerEnd: 1,
  faultDetection: 1,
};

let companyId = "";
let sensorId = "";
let is = [];
let windoTypeArr = [
  { key: "hanning", label: "hanning", value: "hanning" },
  { key: "hamming", label: "hamming", value: "hamming" },
  { key: "rectangular", label: "rectangular", value: "rectangular" },
];

class SensorAdd extends React.Component {
  state = {
    currentTab: "rul",
    currentTab2: "clipping_thresholds",
    sensors: [],
    sensorUnit: ["vibration", "ultrasonic", "temperature", "humidity"],
    sensor: {},
    sensorTypes: [],
    firmwareTypes: [],
    processConfigurations: [],
    mountConfigurations: [],
    tagOptions: [],
    //Navigations
    currentPage: 1,
    saving: false,
    pages: [
      "main",
      "sensorType",
      "firmwareType",
      "processConfigurations",
      "pageMountConfig",
    ],
    defaultSensorType: "",
    defaultFirmwareType: "",
    sensorConfigurations: [],
    firmwareConfigurations: [],
    processConfiguration: {},
    sensorMountInfo: {
      mountMapping: {},
    },
    duplicate: false,
    sensorType: "",
    firmwareType: "",
    mountType: "",
    windowTypeSelected: { key: "hanning", label: "hanning", value: "hanning" },
    assignSensorDIY: false,
    unssignedSensorList: [],
    rpmCopy: "",
    sensorTypeId: "",
    // //Mount Configuration
    // sensorMountInfo: {
    //   mountMapping: {
    //       horizontal: "",
    //       vertical: "",
    //       axial: ""
    //   },
    //   mountType: "",
    //   description: ""
    // }
  };

  async componentDidMount() {
    companyId = this.props.match.params.companyId;
    sensorId = this.props.match.params.sensorId
      ? this.props.match.params.sensorId
      : null;

    if (sensorId) {
      // await this.getSensor();
      let sensor = { ...this.state.sensor };
      sensor.sensorId = sensorId;
      this.setState({ sensor, currentPage: 1 });
    }
    if (
      this.props.location.state &&
      this.props.location.state.sensorTypeId &&
      sensorId
    ) {
      this.props.setSensorTypeId(this.props.location.state.sensorTypeId);
    }

    await this.getSensorTypes();
    await this.getFirmwareTypes();
    await this.getConfig("processConfig", "5db948b6c77abe4a5784603f");
    await this.getMountConfigurations();

    if (
      this.props.location.state &&
      this.props.location.state.componentDetails
    ) {
      console.log("this.props.location.state:", this.props.location.state);
      await this.getConfig(
        "tagId",
        this.props.location.state.componentDetails.tagId
      );
      if (this.props.location.state.componentDetails.changeMountType) {
        let getSensorDetails = await axios.sensorManage_AssignedDetails(
          this.props.location.state.componentDetails.sensorId
        );
        await this.getConfig(
          "mountType",
          getSensorDetails.data.data.mountTypeId
        );
        let index = this.state.mountConfigurations.findIndex(
          (x) => x.value === getSensorDetails.data.data.mountTypeId
        );

        this.setState({
          tagOptions: [
            {
              key: 0,
              label: this.props.location.state.componentDetails.tagId,
              value: this.props.location.state.componentDetails.tagId,
            },
          ],
          sensor: { tagId: this.props.location.state.componentDetails.tagId },
          assignSensorDIY: true,
          readOnly: true,
          defaultMountType: index,
          defaultMountTypeBackup: index,
        });

        return;
      }
      this.setState({
        tagOptions: [
          {
            key: 0,
            label: this.props.location.state.componentDetails.tagId,
            value: this.props.location.state.componentDetails.tagId,
          },
        ],
        sensor: { tagId: this.props.location.state.componentDetails.tagId },
        assignSensorDIY: true,
      });
      this.getUnassignedSensor();
    } else {
      await this.getUnassignedTags();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.sensorTypeIdData !== prevState.sensorTypeIdData) {
      let sensorTypeId = "";
      if (nextProps.sensorTypeIdData) {
        sensorTypeId = nextProps.sensorTypeIdData;
      }
      return {
        sensorTypeId,
      };
    }
  }

  async getUnassignedSensor() {
    let api = await axios.sensorManage_UnassignedList(companyId);

    let tempSensorId = [];

    if (api.data.status === "success" && api.data.data.length > 0) {
      api.data.data.map((val, key) => {
        tempSensorId.push({
          key: key,
          label: val.sensorId,
          value: val.sensorId,
          sensorTypeId: val.sensorTypeId || "",
        });
      });
    }
    this.setState({ unssignedSensorList: tempSensorId });
  }

  async getSensorTypes() {
    let api = await axios.getSensorTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.sensorType,
        value: sen._id,
        id: sen._id,
      });
    });

    this.setState({ sensorTypes: options });
  }

  async getFirmwareTypes() {
    let api = await axios.getFirmwareTypes();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.firmwareType,
        value: sen._id,
        id: sen._id,
      });
    });

    this.setState({ firmwareTypes: options });
  }

  async getMountConfigurations() {
    let endpoint = "/mount-configs";
    let api = await axios.mountConfig_List();
    let options = [];
    api.data.data.forEach((sen, i) => {
      options.push({
        key: i,
        label: sen.label,
        value: sen._id,
        id: sen._id,
      });
    });
    this.setState({ mountConfigurations: options });
  }

  async getUnassignedTags() {
    const response = await Promise.all([
      axios.assetManage_ComponentList(companyId),
      axios.sensorManage_AssignedList(companyId),
    ]);

    let tagIdList = [];
    if (response[0].status === 200) tagIdList = response[0].data.data || [];

    let assinedTagIdList = [];
    if (response[1].status === 200)
      assinedTagIdList = response[1].data.data || [];

    let assinedTagIdDict = {};
    assinedTagIdList.forEach(({ tagId }) => {
      if (tagId) assinedTagIdDict[tagId] = true;
    });

    const tagOptions = [];
    tagIdList.forEach(({ tagId }, key) => {
      if (!tagId) return;
      if (assinedTagIdDict[tagId]) return;

      tagOptions.push({ key, label: tagId, value: tagId });
    });

    this.setState({ tagOptions });
  }

  reduce = (obj, key, val) => {
    var keySplit = key.split(".");
    if (keySplit.length > 1) {
      return this.reduce(
        obj[keySplit[0]],
        keySplit.slice(1, keySplit.length).join("."),
        val
      );
    }
    if (keySplit.length == 1) {
      obj[key] = val;
      return obj[key];
    } else {
      obj = val;
      return obj;
    }
  };

  handleChange =
    (obj, key, index = -1, type = "string") =>
    (e) => {
      let copy = { ...this.state[obj] };
      let value = type === "string" ? e.target.value : e.target.checked;

      if (index >= 0) {
        copy[index][key] = value;
        let config = [...this.state.sensorConfigurations];
        this.setState({ sensorConfigurations: config });
      } else {
        copy[key] = value;
        this.setState({ [obj]: copy });
      }
    };

  handleChangeProcess =
    (param, input, type = "string") =>
    (e) => {
      let copy = { ...this.state[param] };
      if (type === "array") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, [val]);
      } else if (type === "checkbox") {
        this.reduce(copy, input, e.target.checked);
      } else if (type === "number") {
        let val = parseFloat(e.target.value);
        this.reduce(copy, input, val);
      } else {
        this.reduce(copy, input, e.target.value);
      }
      // console.log(copy)
      this.setState({ [param]: copy });
    };

  handleSelectChange =
    (state, key = null) =>
    async (option) => {
      let copy = { ...this.state[state] };
      if (key) {
        copy[key] = option ? option.value : "";
      } else {
        copy = option.value;
      }

      let defaultKey = "";
      let value = option ? option.value : "";
      let selectedTagIdIndex = this.state.tagOptions.findIndex(
        (x) => x.value === option.value
      );
      let defaultValue = selectedTagIdIndex ? selectedTagIdIndex : 0;

      if (key === "sensorId") {
        option.sensorTypeId && this.props.setSensorTypeId(option.sensorTypeId);
        if (
          this.state.processConfiguration &&
          this.state.processConfiguration.notRunningCondition
        ) {
          let { processConfiguration } = this.state;
          processConfiguration.notRunningCondition = {
            ...processConfiguration.notRunningCondition,
            accRmsThreshold:
              option.sensorTypeId == "62f42406a25e4300185c1281" ? 0.21 : 0.08,
          };
          this.setState({ processConfiguration: processConfiguration });
        }
      }
      if (state === "sensorType") {
        defaultKey = "defaultSensorType";
        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("sensorType", value);
      }

      if (state === "firmwareType") {
        defaultKey = "defaultFirmwareType";
        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("firmwareType", value);
      }

      if (state === "mountType") {
        defaultKey = "defaultMountType";
        // Call API and fetch sensorType data from backend and set in state
        await this.getConfig("mountType", value);
      }

      if (key === "tagId") {
        defaultKey = "defaultTagId";
        this.setState({ tagId: value });
        //Call API and fetch tagConfigMap data from server and set in state
        if (option.value) {
          await this.getConfig("tagId", option.value);
          await this.getConfig("trTagProcessConfig", option.value);
        }
      }
      this.setState({ [state]: copy, [defaultKey]: defaultValue });
    };

  handleGMFGearFaultProcess = (param, input, key) => (e) => {
    let copy = { ...this.state[param] };
    let tempAssign = input.split(".");
    copy[tempAssign[0]][tempAssign[1]][key][tempAssign[3]] = e.target.value;
    this.setState({ [param]: copy });
  };

  handleGMFGearFaultAdd = (param) => {
    let variableBearingFaultObj = {
        bpfiAmpThresholdVel: "",
        bpfiAmpThresholdAcc: "1",
        bpfoAmpThresholdVel: "",
        bpfoAmpThresholdAcc: "1",
        bsfAmpThresholdVel: "",
        bsfAmpThresholdAcc: "1",
        apply: true,
        bearingNumber: "",
        bpfiCoeff: "",
        bpfoCoeff: "",
        bsfCoeff: "",
        exclusiveCoeff: "",
        exclusiveHarmonics: "",
        ftfCoeff: "",
        exclusiveFreq: "",
      },
      gear_fault = {
        apply: true,
        gmf: "",
        numOfGearTeeth: "",
        gmfThresholdAcc: "20",
        gEThresholdAcc: "5",
        gearRatio: "",
        rpmDevRatio: "0.1",
        sidebandOrder: "5",
        envRpmOrderLow: "0.8",
        envRpmOrderHigh: "3.2",
      };
    let copy = { ...this.state.processConfiguration };
    if (param === "gear_fault") {
      copy.faultDetection[param].push(gear_fault);
    } else {
      const checkRpm = copy.rul.faultFrequencyAmplitude.rpm.includes("-");
      const bearingFaultObj = variableBearingFaultObj;
      copy.faultDetection[param].push(bearingFaultObj);
    }
    this.setState({ processConfiguration: copy });
  };

  setBearignFaultInputFields(data, bearingFaultAllValues) {
    const variableBearingFaultObj = {
      bpfiAmpThresholdVel: "",
      bpfiAmpThresholdAcc: "1",
      bpfoAmpThresholdVel: "",
      bpfoAmpThresholdAcc: "1",
      bsfAmpThresholdVel: "",
      bsfAmpThresholdAcc: "1",
      apply: bearingFaultAllValues.apply,
      bearingNumber: "",
      bpfiCoeff: "",
      bpfoCoeff: "",
      bsfCoeff: "",
      exclusiveCoeff: "",
      exclusiveHarmonics: "",
      ftfCoeff: "",
      exclusiveFreq: "",
    };
    const bearingFaults = [];

    bearingFaultAllValues.map((oneObject) => {
      let bearingFaultsobj = {};
      const allKeys = Object.keys(variableBearingFaultObj);
      allKeys.map((ele) => {
        bearingFaultsobj[`${ele}`] = oneObject[`${ele}`] || "";
      });
      bearingFaults.push(bearingFaultsobj);
    });

    data.faultDetection.bearing_faults = bearingFaults;
    return data;
  }

  async getConfig(object, id) {
    if (object === "processConfig") {
      let api = await axios.get_Process_config();

      let data = api.data.data;
      if (
        data.processConfigurations.notRunningCondition &&
        this.state.sensorTypeId == "62f42406a25e4300185c1281"
      ) {
        data.processConfigurations.notRunningCondition = {
          ...data.processConfigurations.notRunningCondition,
          accRmsThreshold: 0.21,
        };
      }
      this.setState({
        processConfiguration: data.processConfigurations,
        defaultProcessConfiguration: data.processConfigurations,
        faultDetection: data.processConfigurations.faultDetection,
      });
    }
    if (object === "tagId") {
      let api = await axios.sensorManage_ProcessConfigGetData(id);
      let data = api.data.data;

      if (Object.keys(data).length && data.firmwareConfig) {
        let indexSensorType = this.state.sensorTypes.findIndex(
          (x) => x.value === data.sensorConfig.sensorType
        );
        let indexFirmwareType = this.state.firmwareTypes.findIndex(
          (x) => x.value === data.firmwareConfig.firmwareType
        );
        let indexMountType = this.state.mountConfigurations.findIndex(
          (x) => x.value === data.sensorMountConfig.mountType
        );

        this.setState({
          sensorConfigurations: data.sensorConfig.configurations,
          sensorType: data.sensorConfig.sensorType,
          defaultSensorType: indexSensorType,
          defaultSensorTypeBackup: indexSensorType,

          firmwareConfigurations: data.firmwareConfig.firmwareConfigurations,
          firmwareType: data.firmwareConfig.firmwareType,
          defaultFirmwareType: indexFirmwareType,
          defaultFirmwareTypeBackup: indexFirmwareType,

          // processConfiguration: data.processConfig.processConfigurations,

          sensorMountInfo: data.sensorMountConfig.sensorMountInfo,
          mountType: data.sensorMountConfig.mountType,
          defaultMountType: indexMountType,
        });

        //Merging nonSelected faults so that they can be selected by user
        // check if the object is not null then get the keys into a list
        let appliedFaultsKey =
          data.processConfig &&
          data.processConfig.processConfigurations &&
          data.processConfig.processConfigurations.faultDetection
            ? Object.keys(
                data.processConfig.processConfigurations.faultDetection
              )
            : [];

        let nonAppliedFaults = {};
        Object.keys(this.state.faultDetection).map((fault) => {
          if (!appliedFaultsKey.includes(fault)) {
            nonAppliedFaults[fault] = this.state.faultDetection[fault];
          }
        });

        let faultDetection = {
          ...data.processConfig.processConfigurations.faultDetection,
          ...nonAppliedFaults,
        };
        let processConfigurationsCopy = {
          ...data.processConfig.processConfigurations,
        };
        processConfigurationsCopy.faultDetection = faultDetection;
        const bearingFaultAllValues =
          processConfigurationsCopy.faultDetection.bearing_faults;
        processConfigurationsCopy = this.setBearignFaultInputFields(
          processConfigurationsCopy,
          bearingFaultAllValues
        );
        this.setState({
          processConfiguration: processConfigurationsCopy,
          rpmCopy:
            processConfigurationsCopy.rul.faultFrequencyAmplitude.rpm || "",
        });
      } else {
        //Merging nonSelected faults so that they can be selected by user
        // check if the object is not null then get the keys into a

        let appliedFaultsKey =
          data &&
          data.processConfigurations &&
          data.processConfigurations.faultDetection
            ? Object.keys(data.processConfigurations.faultDetection)
            : [];

        let nonAppliedFaults = {};
        Object.keys(this.state.faultDetection).map((fault) => {
          if (!appliedFaultsKey.includes(fault)) {
            nonAppliedFaults[fault] = this.state.faultDetection[fault];
          }
        });
        let faultDetection = {
          ...data.processConfigurations.faultDetection,
          ...nonAppliedFaults,
        };
        let processConfigurationsCopy = {
          ...data.processConfigurations,
        };
        processConfigurationsCopy.faultDetection = faultDetection;
        const bearingFaultAllValues =
          processConfigurationsCopy.faultDetection.bearing_faults;
        processConfigurationsCopy = this.setBearignFaultInputFields(
          processConfigurationsCopy,
          bearingFaultAllValues
        );
        if (
          processConfigurationsCopy.notRunningCondition &&
          this.state.sensorTypeId == "62f42406a25e4300185c1281"
        ) {
          processConfigurationsCopy.notRunningCondition = {
            ...processConfigurationsCopy.notRunningCondition,
            accRmsThreshold: 0.21,
          };
        }
        this.setState({
          sensorConfigurations: [],
          sensorType: "",
          defaultSensorType: -1,
          defaultSensorTypeBackup: -1,

          firmwareConfigurations: [],
          firmwareType: "",
          defaultFirmwareType: -1,
          defaultFirmwareTypeBackup: -1,

          processConfiguration: processConfigurationsCopy,

          sensorMountInfo: { mountMapping: {} },
          mountType: "",
          defaultMountType: -1,
          rpmCopy:
            processConfigurationsCopy.rul.faultFrequencyAmplitude.rpm || "",
        });
      }
    }
    if (object === "sensorType") {
      let api = await axios.getSensorType_byId(id);
      let data = api.data.data;

      this.setState({ sensorConfigurations: data.sensorConfigurations });
    }

    if (object === "firmwareType") {
      let api = await axios.getFirmwareType_byId(id);
      let data = api.data.data;

      this.setState({ firmwareConfigurations: data.firmwareConfigurations });
    }

    if (object === "trTagProcessConfig") {
      let endpoint = "/process-configs/trTagProcessconfig/" + id;
      let api = await axios.getData(endpoint);
      let data = api.data.data;
      if (
        data &&
        data.processConfigurations &&
        data.processConfigurations.faultDetection
      ) {
        let faultDetection = this.state.faultDetection;
        let keys = Object.keys(data.processConfigurations.faultDetection);
        for (let fault in faultDetection) {
          if (!keys.includes(fault)) {
            data.processConfigurations.faultDetection[fault] =
              faultDetection[fault];
          }
        }
        this.setState({
          processConfiguration: data.processConfigurations,
          defaultProcessConfiguration: data.processConfigurations,
          faultDetection: data.processConfigurations.faultDetection,
        });
      }
    }

    if (object === "mountType") {
      let api = await axios.mountConfig_details(id);
      let data = api.data.data;

      this.setState({ sensorMountInfo: data.sensorMountInfo });
    }
  }

  handleCheckBoxChange = (key) => (e) => {
    this.setState({ [key]: e.target.checked });
  };

  processConfigMnupulation = () => {
    let processConfigurations = {};
    processConfigurations.processConfigurations = {
      ...this.state.processConfiguration,
    };
    processConfigurations.tagId = this.state.tagId;
    processConfigurations.processConfigurations.windowType =
      this.state.windowTypeSelected.value;
    let tempFaultDetection = {},
      tempAEFaultDetection = {};
    Object.entries(
      processConfigurations.processConfigurations.faultDetection
    ).forEach(([key, value]) => {
      if (value.apply === false) return;
      tempFaultDetection = { ...tempFaultDetection, [key]: value };
    });

    if (processConfigurations.processConfigurations.aeFaultDetectio) {
      Object.entries(
        processConfigurations.processConfigurations.aeFaultDetection
      ).forEach(([key, value]) => {
        if (value.apply === false) return;
        tempAEFaultDetection = { ...tempAEFaultDetection, [key]: value };
      });
    }

    processConfigurations.processConfigurations.faultDetection =
      tempFaultDetection;
    processConfigurations.processConfigurations.aeFaultDetection =
      tempAEFaultDetection;
    return processConfigurations;
  };

  handleNext = async (page) => {
    if (this.state.currentPage === 5) {
      //Checking mount type is selected or not
      let conf = this.state.sensorMountInfo.mountType;
      if (!conf) {
        toast.warn("Please select Sensor Mount Configuration");
        this.setState({ saving: false });
        return;
      }

      this.setState({ saving: true });
      localStorage.removeItem("companyId");

      //Sensor
      let sensor = { ...this.state.sensor };
      sensor.companyId = companyId;
      sensor.mountTypeId = this.state.mountType;

      //Sensor Config
      let sensorConfig = {};
      sensorConfig.tagId = this.state.tagId;
      sensorConfig.sensorId = this.state.sensor.sensorId;
      sensorConfig.sensorType = this.state.sensorType;
      sensorConfig.configurations = [
        { ...this.state.sensorConfigurations[0] },
        { ...this.state.sensorConfigurations[1] },
        { ...this.state.sensorConfigurations[2] },
        { ...this.state.sensorConfigurations[3] },
      ];
      // console.log(sensorConfig, 'sensor Configuration');

      //Firmware Config
      let firmwareConfigurations = {};
      firmwareConfigurations.firmwareType = this.state.firmwareType;
      firmwareConfigurations.firmwareConfigurations = [
        { ...this.state.firmwareConfigurations[0] },
        { ...this.state.firmwareConfigurations[1] },
        { ...this.state.firmwareConfigurations[2] },
        { ...this.state.firmwareConfigurations[3] },
      ];
      firmwareConfigurations.tagId = this.state.tagId;
      // console.log(processConfigurations, 'processConfigurations');

      //Process Config
      const processConfigurations = this.processConfigMnupulation();
      //Sensor Mount Config
      let sensorMountConfig = {};
      sensorMountConfig.mountType = this.state.mountType;
      sensorMountConfig.sensorMountInfo = { ...this.state.sensorMountInfo };
      sensorMountConfig.tagId = sensor.tagId;
      sensorMountConfig.sensorId = sensor.sensorId;
      sensorMountConfig.description = "";
      delete sensorMountConfig.sensorMountInfo.description;
      // console.log(sensorMountConfig, 'sensorMountConfiguration');

      let data = {
        sensorConfig: sensorConfig,
        sensorFirmwareConfig: firmwareConfigurations,
        sensorProcessConfig: processConfigurations,
        sensorMountConfig: sensorMountConfig,
      };
      console.log(data, "data");

      // console.log({ data, sensor, state: this.state, props: this.props });
      // return;

      let sensorApiStatus = "";
      let sensorApiMessage = "";

      // return;

      //Saving sensor
      let sensorAPI = await this.saveSensor(sensor);
      sensorApiStatus = sensorAPI.status;
      sensorApiMessage = sensorAPI.data && sensorAPI.data.data;

      //If sensor api returned 200 status code then save configurations
      if (sensorApiStatus === 200) {
        toast.success("Sensor saved successfully.");
        toast.info("Saving Configurations.", { autoClose: 7000 });
        let sensorConfigAPI = "";

        setTimeout(async () => {
          sensorConfigAPI = await this.saveSensorConfig(
            data.sensorProcessConfig.processConfigurations,
            sensor.tagId,
            true
          );
          // let link = `https://sensorapi.nanoprecisedataservices.com/coordinator/api/v1/sensors-keys?sensorIdList=${
          //   sensorConfig.sensorId
          // }&logglyTag=${companyId}-${null}-${sensorConfig.sensorId}`;

          // let apiTest = await axios.putReq(link, {});
          // console.log("apiTestapiTestapiTest:", apiTest);

          // const requestOptions = {
          //   method: "PUT",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization: `Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcFpDSTZJbVZzWldOMGNtbGpTVzF3SW4wLjQ2eTdsVjNrUl9ZNlBVOFpNYVFxUHZMcV94aTFWcEwwbUhvempUWVFkSHc6ZTRjZjVkN2ViMDkwNGJmYmFhZTIyOTQ0MGNlMGYzYWM=`,
          //   },
          // };
          let dataPayload = {
            dataPayload: [
              {
                sensorId: sensorConfig.sensorId,
                tag: `${companyId}-${sensorConfig.sensorId}`,
              },
            ],
            referenceId: Math.floor(10000 + Math.random() * 90000),
          };
          const responseTemp = await axios.put_sensorCoordinator(dataPayload);

          if (sensorConfigAPI && sensorConfigAPI.status === 200) {
            toast.success("Setting things up.", { autoClose: 5000 });
            this.jump(`/v1/companies/${companyId}/sensors`, 5000);
          } else if (
            (sensorConfigAPI && sensorConfigAPI.status === 400) ||
            (sensorConfigAPI && sensorConfigAPI.status === 404)
          ) {
            toast.warn("Failed to save");
            this.setState({ saving: false });
          } else if (sensorConfigAPI && sensorConfigAPI.status === 500) {
            toast.error("Something went wrong. Please try again.");
            this.setState({ saving: false });
          }
        }, 3000);
      } else if (sensorApiStatus === 400) {
        this.setState({ saving: false });
        toast.warn(sensorApiMessage);
      } else if (sensorApiStatus === 500) {
        this.setState({ saving: false });
        toast.error("Something went wrong. Please try again.");
      }
    } else {
      let valid = true;
      if (this.state.currentPage === 1) {
        let sensor = { ...this.state.sensor };
        //Validating
        let err = await validation.validate(sensor);
        if (err) {
          toast.warn(err);
          this.setState({ saving: false });
          return;
        }
      }

      if (this.state.currentPage === 2) {
        //Checking sensor type is selected or not
        let conf = this.state.sensorConfigurations.length;
        if (!conf) {
          toast.warn("Please select Sensor Type");
          this.setState({ saving: false });
          return;
        }
      }

      if (this.state.currentPage === 3) {
        //Checking firmware type is selected or not
        let conf = this.state.firmwareConfigurations.length;
        if (!conf) {
          toast.warn("Please select Firmware Type");
          this.setState({ saving: false });
          return;
        }
      }

      if (this.state.currentPage === 4) {
        //Checking if single fault is selected or not
        let faultDetection = this.state.processConfiguration.faultDetection;
        let valid = false;
        Object.keys(faultDetection).forEach((f) => {
          if (faultDetection[f].apply) {
            valid = true;
          }
          if (f === "gear_fault" || f === "bearing_faults") {
            if (faultDetection[f][0]["apply"]) {
              valid = true;
            }
          }
        });

        if (!valid) {
          toast.warn("Select atleast one fault");
          this.setState({ saving: false });
          return;
        }
      }

      if (valid)
        this.setState({
          currentPage:
            this.state.currentPage === 1
              ? this.state.currentPage + 3
              : this.state.currentPage + 1,
        });
    }
  };

  handleCancel = (page) => {
    if (page === "main") {
      this.jump("/v1/companies/" + companyId + "/sensors");
    }
    this.setState({
      currentPage:
        this.state.currentPage === 4
          ? this.state.currentPage - 3
          : this.state.currentPage - 1,
    });
  };

  async saveSensor(sensor) {
    let sensorAPI = await axios.sensorManage_Assign(sensor);
    console.log("pppppoooo", sensorAPI);
    if (sensorAPI.status === 400 || sensorAPI.status === 404) {
      toast.warn(sensorAPI.data.data);
      this.jump(`/v1/companies/${companyId}/sensors`, 5000);
    }
    return {
      status: sensorAPI.status,
      message: sensorAPI && sensorAPI.data && sensorAPI.data.message,
      data: sensorAPI && sensorAPI.data,
    };
  }

  async saveSensorConfig(sensorConfig, tagId, addConfigurationPage) {
    Object.assign(sensorConfig.notRunningCondition, {
      accRmsThreshold: parseFloat(
        sensorConfig.notRunningCondition.accRmsThreshold
      ),
    });
    let tempData = {
      processConfigurations: sensorConfig,
      tagId,
    };
    const tempFault =
      tempData.processConfigurations &&
      tempData.processConfigurations.faultDetection;

    if (!tempFault.bearing_faults[0].apply) {
      delete tempData.processConfigurations.faultDetection.bearing_faults;
    }
    if (!tempFault.gear_fault[0].apply) {
      delete tempData.processConfigurations.faultDetection.gear_fault;
    }
    let sensorConfigAPI = null;
    const isConfigured = await axios.configTypeDetails(tagId);
    if (isConfigured.data.data.isOldConfig == true) {
      sensorConfigAPI = await axios.sensorManage_ProcessConfigUpdate(
        tagId,
        tempData
      );
    } else {
      sensorConfigAPI = await axios.sensorManage_ProcessConfig(tempData);
    }
    if (addConfigurationPage) {
      if (sensorConfigAPI && sensorConfigAPI.status == 404) {
        toast.warn(sensorConfigAPI.data.data);
        this.jump(`/v1/companies/${companyId}/sensors`, 5000);
      } else if (sensorConfigAPI.status == 200) {
        toast.success("Configured Successfully");
        this.jump(`/v1/companies/${companyId}/sensors`, 5000);
      }
    }
  }

  jump(url, delay = 0) {
    setTimeout(() => {
      this.props.history.push(url);
    }, delay);
  }

  handleExpandChange = (panel) => (e, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  // handleTabChange = (index, lastindex, event) => {
  //   let currentTab = Object.keys(this.state.processConfiguration)[index];
  //   let currentTab2 = Object.keys(this.state.processConfiguration[currentTab])[0];
  //   let objectLevel = levels[currentTab];

  //   this.setState({currentTab: currentTab, currentTab2: currentTab2, objectLevel: objectLevel});
  // }

  // handleTabChange2 = (index, lastindex, event) => {
  //   this.setState({currentTab2: Object.keys(this.state.processConfiguration[this.state.currentTab])[index]})
  // }

  render() {
    let processConfig = "";

    processConfig = Object.keys(this.state.processConfiguration).map(
      (field1, i) => {
        return (
          <TabPanel style={{ marginBottom: "20px" }}>
            {levels[field1] === 2 ? (
              <Tabs onSelect={this.handleTabChange2}>
                <TabList>
                  {Object.keys(this.state.processConfiguration[field1]).map(
                    (field2, i) => {
                      return (
                        <Tab key={i} style={{ color: "#e57373" }}>
                          {field2}
                        </Tab>
                      );
                    }
                  )}
                </TabList>
                {Object.keys(this.state.processConfiguration[field1]).map(
                  (field2, i) => {
                    return (
                      <TabPanel key={i}>
                        <Row className="mt-5">
                          {Object.keys(
                            this.state.processConfiguration[field1][field2]
                          ).map((field3, i) => {
                            let type = "";
                            if (
                              field2 === "faultFrequencies" ||
                              field2 === "faultFrequencyThresholds" ||
                              field2 === "powerFunctionCoefficients"
                            ) {
                              type = "array";
                            }
                            if (
                              field2 === "clipping_thresholds" ||
                              field2 === "faultFrequencyData" ||
                              field2 === "vibration" ||
                              field2 === "ultrasonic"
                            ) {
                              type = "number";
                            }

                            if (field2 === "boundLimits") {
                              type = field3 === "lowerLimit" ? "number" : "";
                            }
                            return (
                              <Col key={i} lg="4">
                                <FormGroup>
                                  <label className="form-control-label">
                                    {field3}
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-username"
                                    type="text"
                                    value={
                                      this.state.processConfiguration[field1][
                                        field2
                                      ][field3]
                                    }
                                    onChange={this.handleChangeProcess(
                                      "processConfiguration",
                                      `${field1}.${field2}.${field3}`,
                                      type
                                    )}
                                    onBlur={(e) => {
                                      if (
                                        `${field1}.${field2}.${field3}` ===
                                        `rul.faultFrequencyAmplitude.rpm`
                                      ) {
                                        if (
                                          (e.target.value.includes("-") &&
                                            !this.state.rpmCopy.includes(
                                              "-"
                                            )) ||
                                          (!e.target.value.includes("-") &&
                                            this.state.rpmCopy.includes("-"))
                                        ) {
                                          this.setBearignFaultInputFields(
                                            this.state.processConfiguration,
                                            this.state
                                              .defaultProcessConfiguration
                                              .faultDetection.bearing_faults
                                          );
                                        }
                                        if (e.target.value === "") {
                                          // if rmp empty remove selected bearing_faluts
                                          const processConfigurationCopy =
                                            this.state.processConfiguration;
                                          const bearingFaults =
                                            processConfigurationCopy.faultDetection.bearing_faults.map(
                                              (ele) => ({
                                                ...ele,
                                                apply: false,
                                              })
                                            );
                                          this.setState({
                                            processConfiguration: {
                                              ...processConfigurationCopy,
                                              faultDetection: {
                                                ...processConfigurationCopy.faultDetection,
                                                bearing_faults: bearingFaults,
                                              },
                                            },
                                          });
                                        }
                                        this.setState({
                                          rpmCopy: e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            );
                          })}
                        </Row>
                      </TabPanel>
                    );
                  }
                )}
              </Tabs>
            ) : (
              <Row className="mt-5">
                {field1 != "faultDetection" && field1 != "aeFaultDetection"
                  ? Object.keys(this.state.processConfiguration[field1]).map(
                      (field2, i) => {
                        let type = "";
                        if (field2 === "accRmsThreshold") {
                          type = "number";
                        }
                        return (
                          <Col key={i} lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                {field1 !== "windowType" ? field2 : null}
                              </label>
                              {field1 === "windowType" ? (
                                <Select
                                  onChange={(e) => {
                                    this.setState({ windowTypeSelected: e });
                                  }}
                                  value={this.state.windowTypeSelected}
                                  options={windoTypeArr}
                                />
                              ) : (
                                <Input
                                  disabled={this.state.readOnly}
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.processConfiguration[field1][
                                      field2
                                    ]
                                  }
                                  onChange={this.handleChangeProcess(
                                    "processConfiguration",
                                    `${field1}.${field2}`,
                                    type
                                  )}
                                />
                              )}
                            </FormGroup>
                          </Col>
                        );
                      }
                    )
                  : Object.keys(this.state.processConfiguration[field1]).map(
                      (fault, i) => {
                        if (
                          fault === "bearing_faults" ||
                          fault === "gear_fault"
                        ) {
                          return (
                            <Container maxWidth="sm">
                              <ExpansionPanel
                                expanded={this.state.expanded === "panel" + i}
                                onChange={this.handleExpandChange("panel" + i)}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-label="Expand"
                                  aria-controls="additional-actions1-content"
                                  id="additional-actions1-header"
                                >
                                  <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        checked={
                                          fault === "gear_fault" ||
                                          fault === "bearing_faults"
                                            ? fault === "gear_fault" ||
                                              (fault === "bearing_faults" &&
                                                this.state.rpmCopy !== "")
                                              ? Boolean(
                                                  this.state
                                                    .processConfiguration[
                                                    field1
                                                  ][fault][0]["apply"]
                                                )
                                              : null
                                            : Boolean(
                                                this.state.processConfiguration[
                                                  field1
                                                ][fault]["apply"]
                                              )
                                        }
                                        onChange={(e) => {
                                          let processConfiguration = {
                                            ...this.state.processConfiguration,
                                          };
                                          if (
                                            fault === "gear_fault" ||
                                            fault === "bearing_faults"
                                          ) {
                                            if (
                                              fault === "gear_fault" ||
                                              (fault === "bearing_faults" &&
                                                this.state.rpmCopy !== "")
                                            ) {
                                              processConfiguration[field1][
                                                fault
                                              ][0]["apply"] = e.target.checked;
                                            }
                                          } else {
                                            processConfiguration[field1][fault][
                                              "apply"
                                            ] = e.target.checked;
                                          }
                                          this.setState({
                                            processConfiguration,
                                          });
                                          // console.log(e.target.checked)
                                          // console.log(processConfiguration['faultDetection'][fault]['apply'])
                                        }}
                                      ></Checkbox>
                                    }
                                    label={fault}
                                  />
                                </ExpansionPanelSummary>

                                <ExpansionPanelDetails>
                                  {fault === "bearing_faults" &&
                                  this.state.rpmCopy === "" ? (
                                    <Container maxWidth="sm">
                                      Please provide the rpm value
                                    </Container>
                                  ) : (
                                    <Container maxWidth="sm">
                                      <Row>
                                        <Button
                                          onClick={(e) => {
                                            switch (fault) {
                                              case "bearing_faults":
                                                this.handleGMFGearFaultAdd(
                                                  "bearing_faults"
                                                );
                                                break;
                                              case "gear_fault":
                                                this.handleGMFGearFaultAdd(
                                                  "gear_fault"
                                                );
                                                break;

                                              default:
                                                break;
                                            }
                                          }}
                                        >
                                          ADD NEW
                                        </Button>
                                      </Row>
                                      <Row>
                                        {this.state.processConfiguration[
                                          field1
                                        ][fault].map((iArrFault, key) => {
                                          return (
                                            <Row>
                                              {"\n"}
                                              {"\n"}
                                              {"\n"}
                                              <label> </label>
                                              <label>
                                                <b>{key}</b>
                                              </label>
                                              {Object.keys(
                                                this.state.processConfiguration[
                                                  field1
                                                ][fault][key]
                                              ).map((field2, i) => {
                                                if (field2 != "apply") {
                                                  let element = [];
                                                  element.push(
                                                    <Col key={i} lg="3">
                                                      <FormGroup>
                                                        <label className="form-control-label">
                                                          {field2}
                                                        </label>
                                                        <Input
                                                          disabled={
                                                            this.state.readOnly
                                                          }
                                                          className="form-control-alternative"
                                                          id="input-username"
                                                          type="text"
                                                          value={
                                                            this.state
                                                              .processConfiguration[
                                                              field1
                                                            ][fault][key][
                                                              field2
                                                            ]
                                                          }
                                                          onChange={this.handleGMFGearFaultProcess(
                                                            "processConfiguration",
                                                            `${field1}.${fault}.${key}.${field2}`,
                                                            key
                                                          )}
                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  );
                                                  return element;
                                                }
                                              })}
                                            </Row>
                                          );
                                        })}
                                      </Row>
                                    </Container>
                                  )}
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </Container>
                          );
                        }
                        return (
                          <Container maxWidth="sm">
                            <ExpansionPanel
                              expanded={this.state.expanded === "panel" + i}
                              onChange={this.handleExpandChange("panel" + i)}
                            >
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                              >
                                <FormControlLabel
                                  aria-label="Acknowledge"
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  control={
                                    field1 === "faultDetection" ? (
                                      <Checkbox
                                        checked={Boolean(
                                          this.state.processConfiguration[
                                            field1
                                          ][fault]["apply"]
                                        )}
                                        onChange={(e) => {
                                          let processConfiguration = {
                                            ...this.state.processConfiguration,
                                          };
                                          // console.log(processConfiguration);

                                          processConfiguration[
                                            "faultDetection"
                                          ][fault]["apply"] = e.target.checked;
                                          this.setState({
                                            processConfiguration,
                                          });
                                        }}
                                      ></Checkbox>
                                    ) : (
                                      <Checkbox
                                        checked={Boolean(
                                          this.state.processConfiguration[
                                            field1
                                          ][fault]["apply"]
                                        )}
                                        onChange={(e) => {
                                          let processConfiguration = {
                                            ...this.state.processConfiguration,
                                          };
                                          // console.log(processConfiguration);

                                          processConfiguration[
                                            "aeFaultDetection"
                                          ][fault]["apply"] = e.target.checked;
                                          this.setState({
                                            processConfiguration,
                                          });
                                        }}
                                      ></Checkbox>
                                    )
                                  }
                                  label={fault}
                                />
                              </ExpansionPanelSummary>

                              <ExpansionPanelDetails>
                                <Container maxWidth="sm">
                                  <Row>
                                    {Object.keys(
                                      this.state.processConfiguration[field1][
                                        fault
                                      ]
                                    ).map((field2, i) => {
                                      if (field2 != "apply") {
                                        return (
                                          <Col key={i} lg="3">
                                            <FormGroup>
                                              <label className="form-control-label">
                                                {field2}
                                              </label>

                                              <Input
                                                disabled={this.state.readOnly}
                                                className="form-control-alternative"
                                                id="input-username"
                                                type="text"
                                                value={
                                                  this.state
                                                    .processConfiguration[
                                                    field1
                                                  ][fault][field2]
                                                }
                                                onChange={this.handleChangeProcess(
                                                  "processConfiguration",
                                                  `${field1}.${fault}.${field2}`
                                                )}
                                              />
                                            </FormGroup>
                                          </Col>
                                        );
                                      }
                                    })}
                                  </Row>
                                </Container>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </Container>
                        );
                      }
                    )}
              </Row>
            )}
          </TabPanel>
        );
      }
    );

    let label = "";
    if (this.state.currentPage === 1) label = "Sensor";
    else if (this.state.currentPage === 2) label = "Sensor Configurations";
    else if (this.state.currentPage === 3) label = "Firmware Configuration";
    else if (this.state.currentPage === 4) label = "Process Configuration";
    else if (this.state.currentPage === 5) label = "Mount Configurations";

    let back = "";
    if (this.state.currentPage === 1) back = "Cancel";
    else if (this.state.currentPage === 2) back = "Sensor";
    else if (this.state.currentPage === 3) back = "Sensor Configuration";
    else if (this.state.currentPage === 4) back = "Sensor";
    else if (this.state.currentPage === 5) back = "Process Configuration";

    let front = "";
    if (this.state.currentPage === 1) front = "Process Configurations";
    else if (this.state.currentPage === 2) front = "Firmware Configuration";
    else if (this.state.currentPage === 3) front = "Process Configurations";
    else if (this.state.currentPage === 4) front = "Mount Configuration";
    else if (this.state.currentPage === 5) front = "Submit";

    return (
      <>
        <ToastContainer position="top-left" />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col lg="6 ">
              <div
                onClick={() => {
                  this.props.history.goBack();
                  // this.jump("/v1/companies/" + companyId + "/sensors", 0);
                }}
                style={{ width: "2rem", height: "2rem", cursor: "pointer" }}
                className="icon custom-icon icon-shape bg-orange text-white rounded-circle shadow"
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>
              </div>
            </Col>
            <Col lg="6 table-caption">
              {this.state.assignSensorDIY ? (
                <h1>Assign Sensor</h1>
              ) : sensorId ? (
                <h1>Add Configurations</h1>
              ) : (
                <h1>Add Sensor & Configurations</h1>
              )}
            </Col>
            <div className="col">
              <Card className="bg-secondary shadow">
                {/* Add Sensor */}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {this.state.currentPage > 0 && (
                        <h3 className="mb-0">{label}</h3>
                      )}
                    </Col>
                    <Col className="text-right" xs="4"></Col>
                  </Row>
                </CardHeader>

                {this.state.currentPage > 0 && (
                  <CardBody>
                    {this.state.currentPage === 1 && (
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Sensor Id
                              </label>
                              {this.state.assignSensorDIY ? (
                                <Select
                                  isClearable={false}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "sensor",
                                    "sensorId"
                                  )}
                                  defaultValue={
                                    this.props.location.state.componentDetails
                                      .changeMountType
                                      ? {
                                          key: 0,
                                          label:
                                            this.props.location.state
                                              .componentDetails.sensorId,
                                          value:
                                            this.props.location.state
                                              .componentDetails.sensorId,
                                        }
                                      : null
                                  }
                                  options={this.state.unssignedSensorList}
                                />
                              ) : (
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={this.state.sensor.sensorId}
                                  onChange={this.handleChange(
                                    "sensor",
                                    "sensorId"
                                  )}
                                  disabled={this.state.readOnly || sensorId}
                                />
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                              >
                                Tag Id
                              </label>
                              <Select
                                isClearable={false}
                                isDisabled={this.state.readOnly}
                                onChange={this.handleSelectChange(
                                  "sensor",
                                  "tagId"
                                )}
                                value={
                                  this.state.assignSensorDIY
                                    ? this.state.tagOptions[0]
                                    : this.state.tagOptions[
                                        this.state.defaultTagId
                                      ]
                                }
                                defaultValue={
                                  this.state.tagOptions[this.state.defaultTagId]
                                }
                                options={this.state.tagOptions}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {this.state.assignSensorDIY ? (
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Configurations
                                </label>
                                <Select
                                  isClearable={false}
                                  onChange={this.handleSelectChange(
                                    "mountType"
                                  )}
                                  defaultValue={
                                    this.state.mountConfigurations[
                                      this.state.defaultMountType
                                    ]
                                  }
                                  options={this.state.mountConfigurations}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <AxisImage />
                            </Col>

                            {/* <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Horizontal:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .horizontal
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Vertical:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .vertical
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label className="form-control-label">
                                  Axial:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={
                                    this.state.sensorMountInfo.mountMapping
                                      .axial
                                  }
                                  // onChange={this.handleChange('sensorMountInfo', 'description')}
                                />
                              </FormGroup>
                            </Col>

                            <Col lg="6">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Type:
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-username"
                                  type="text"
                                  value={this.state.sensorMountInfo.mountType}
                                  onChange={this.handleChange(
                                    "sensorMountInfo",
                                    "description"
                                  )}
                                />
                              </FormGroup>
                            </Col> */}
                          </Row>
                        ) : null}
                      </div>
                    )}

                    {this.state.currentPage === 2 && (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Sensor Types:
                            </label>
                            <Select
                              isClearable={false}
                              isDisabled={this.state.readOnly}
                              onChange={this.handleSelectChange("sensorType")}
                              defaultValue={
                                this.state.sensorTypes[
                                  this.state.defaultSensorType
                                ]
                              }
                              options={this.state.sensorTypes}
                            />
                          </FormGroup>
                        </Col>

                        <div className="pl-lg-4">
                          <Tabs>
                            <TabList>
                              {this.state.sensorUnit.length &&
                                this.state.sensorUnit.map((tab, i) => (
                                  <Tab key={i}>
                                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                  </Tab>
                                ))}
                            </TabList>
                            <br />
                            {this.state.sensorConfigurations.length
                              ? this.state.sensorConfigurations.map(
                                  (field, j) => {
                                    if (field != "sensor") {
                                      return (
                                        <TabPanel key={j}>
                                          {Object.keys(field).map((key) => {
                                            if (key != "sensor") {
                                              return (
                                                <Row key={key + j}>
                                                  <Col lg="1"></Col>
                                                  <Col lg="3">
                                                    <FormGroup>
                                                      <label className="form-control-label">
                                                        {key}:
                                                      </label>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col lg="6">
                                                    <FormGroup>
                                                      {key !=
                                                      "adcConvertion" ? (
                                                        <Input
                                                          className="form-control-alternative"
                                                          id="input-username"
                                                          type="text"
                                                          value={field[key]}
                                                          readOnly={
                                                            this.state.readOnly
                                                          }
                                                          onChange={this.handleChange(
                                                            "sensorConfigurations",
                                                            key,
                                                            j
                                                          )}
                                                        />
                                                      ) : (
                                                        <Checkbox
                                                          checked={field[key]}
                                                          onChange={this.handleChange(
                                                            "sensorConfigurations",
                                                            key,
                                                            j,
                                                            "checkbox"
                                                          )}
                                                          color="primary"
                                                          inputProps={{
                                                            "aria-label":
                                                              "primary checkbox",
                                                          }}
                                                          disabled={
                                                            this.state.readOnly
                                                          }
                                                        />
                                                      )}
                                                    </FormGroup>
                                                  </Col>
                                                  <Col lg="2"></Col>
                                                </Row>
                                              );
                                            }
                                          })}
                                        </TabPanel>
                                      );
                                    }
                                  }
                                )
                              : "Select Sensor Type"}
                          </Tabs>
                        </div>
                      </>
                    )}

                    {this.state.currentPage === 3 && (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Firmware Types:
                            </label>
                            <Select
                              isClearable={false}
                              isDisabled={this.state.readOnly}
                              onChange={this.handleSelectChange("firmwareType")}
                              defaultValue={
                                this.state.firmwareTypes[
                                  this.state.defaultFirmwareType
                                ]
                              }
                              options={this.state.firmwareTypes}
                            />
                          </FormGroup>
                        </Col>

                        <div className="pl-lg-4">
                          <Tabs>
                            <TabList>
                              {this.state.sensorUnit.length &&
                                this.state.sensorUnit.map((tab, i) => (
                                  <Tab key={i}>
                                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                                  </Tab>
                                ))}
                              {/* <Tab>ADC Convertion</Tab> */}
                            </TabList>
                            <br />
                            {this.state.firmwareConfigurations.length
                              ? this.state.firmwareConfigurations.map(
                                  (field, j) => {
                                    if (field != "sensor") {
                                      return (
                                        <TabPanel key={j}>
                                          {Object.keys(field).map((key) => {
                                            if (key != "sensor") {
                                              return (
                                                <Row key={key + j}>
                                                  <Col lg="1"></Col>
                                                  <Col lg="3">
                                                    <FormGroup>
                                                      <label className="form-control-label">
                                                        {key}:
                                                      </label>
                                                    </FormGroup>
                                                  </Col>
                                                  <Col lg="6">
                                                    <FormGroup>
                                                      {key !=
                                                      "adcConvertion" ? (
                                                        <Input
                                                          className="form-control-alternative"
                                                          id="input-username"
                                                          type="text"
                                                          value={field[key]}
                                                          onChange={this.handleChange(
                                                            "firmwareConfigurations",
                                                            key,
                                                            j
                                                          )}
                                                        />
                                                      ) : (
                                                        <Input
                                                          className="form-control-alternative"
                                                          id="input-username"
                                                          type="checkbox"
                                                          checked={field[key]}
                                                          onChange={this.handleChange(
                                                            "firmwareConfigurations",
                                                            key,
                                                            j,
                                                            field,
                                                            "checkbox"
                                                          )}
                                                        />
                                                      )}
                                                    </FormGroup>
                                                  </Col>
                                                  <Col lg="2"></Col>
                                                </Row>
                                              );
                                            }
                                          })}
                                        </TabPanel>
                                      );
                                    }
                                  }
                                )
                              : "Select Firmware Type"}
                          </Tabs>
                        </div>
                      </>
                    )}

                    {this.state.currentPage === 4 && (
                      <>
                        <div className="pl-lg-4">
                          <Tabs onSelect={this.handleTabChange}>
                            <TabList>
                              {Object.keys(this.state.processConfiguration).map(
                                (field, i) => {
                                  return (
                                    <Tab
                                      style={{
                                        backgroundColor: "#FFB74D",
                                        color: "#fff",
                                      }}
                                    >
                                      {field}
                                    </Tab>
                                  );
                                }
                              )}
                            </TabList>

                            {processConfig}
                          </Tabs>
                        </div>
                      </>
                    )}

                    {this.state.currentPage === 5 && (
                      <div className="pl-lg-4">
                        <>
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <label className="form-control-label">
                                  Mount Configurations
                                </label>
                                <Select
                                  isClearable={false}
                                  isDisabled={this.state.readOnly}
                                  onChange={this.handleSelectChange(
                                    "mountType"
                                  )}
                                  defaultValue={
                                    this.state.mountConfigurations[
                                      this.state.defaultMountType
                                    ]
                                  }
                                  options={this.state.mountConfigurations}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="12">
                              <AxisImage />
                            </Col>
                          </Row>
                        </>
                      </div>
                    )}

                    {this.state.currentPage <= 5 && (
                      <Row>
                        <Col lg="12">
                          {this.state.assignSensorDIY &&
                          this.state.currentPage === 1 ? (
                            <Button
                              className="right"
                              color="success"
                              onClick={async () => {
                                if (this.state.mountType === "") {
                                  toast.error("Select Mount Type");
                                  return;
                                }

                                if (
                                  this.props.location.state.componentDetails
                                    .changeMountType
                                ) {
                                  let sensorAssignAPI =
                                    await axios.sensorManage_Reassign(
                                      this.props.location.state.componentDetails
                                        .sensorId,
                                      { mountTypeId: this.state.mountType }
                                    );
                                  if (sensorAssignAPI.status === 200) {
                                    toast.success("Mount type Updated!");
                                  } else {
                                    toast.error("Update failed");
                                  }
                                  return;
                                }

                                if (
                                  this.state.sensor &&
                                  this.state.sensor.sensorId &&
                                  this.state.mountType
                                ) {
                                  let sensorTemp = {
                                    sensorId: this.state.sensor.sensorId,
                                    companyId: companyId,
                                    tagId: this.state.tagOptions[0].value,
                                    mountTypeId: this.state.mountType,
                                  };
                                  let sensorAssignAPI =
                                    await axios.sensorManage_Assign(sensorTemp);
                                  if (sensorAssignAPI.status === 200) {
                                    const processConfigurations =
                                      this.processConfigMnupulation();
                                    this.saveSensorConfig(
                                      processConfigurations.processConfigurations,
                                      this.state.tagOptions[0].value,
                                      false
                                    );
                                    // let link = `https://sensorapi.nanoprecisedataservices.com/coordinator/api/v1/sensors-keys?sensorIdList=${
                                    //   this.state.sensor.sensorId
                                    // }&logglyTag=${companyId}-${
                                    //   this.props.location.state.plantName
                                    //     ? this.props.location.state.plantName
                                    //     : null
                                    // }-${this.state.sensor.sensorId}`;

                                    // // let apiTest = await axios.putReq(link, {});
                                    // // console.log(
                                    // //   "apiTestapiTestapiTest:",
                                    // //   apiTest
                                    // // );

                                    // const requestOptions = {
                                    //   method: "PUT",
                                    //   headers: {
                                    //     "Content-Type": "application/json",
                                    //     Authorization: `Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuZXlKcFpDSTZJbVZzWldOMGNtbGpTVzF3SW4wLjQ2eTdsVjNrUl9ZNlBVOFpNYVFxUHZMcV94aTFWcEwwbUhvempUWVFkSHc6ZTRjZjVkN2ViMDkwNGJmYmFhZTIyOTQ0MGNlMGYzYWM=`,
                                    //   },
                                    // };
                                    let data = {
                                      dataPayload: [
                                        {
                                          sensorId: this.state.sensor.sensorId,
                                          tag: `${companyId}-${this.state.sensor.sensorId}`,
                                        },
                                      ],
                                      referenceId: Math.floor(
                                        10000 + Math.random() * 90000
                                      ),
                                    };
                                    const responseTemp =
                                      await axios.put_sensorCoordinator(data);

                                    toast.success("Sensor Assigned!");
                                    this.props.history.goBack();
                                  }
                                } else {
                                  toast.error("Add sensorId");
                                }
                              }}
                              size="md"
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              className="right"
                              color="success"
                              onClick={() =>
                                this.handleNext(
                                  this.state.pages[this.state.currentPage + 1]
                                )
                              }
                              size="md"
                              disabled={this.state.saving}
                            >
                              {this.state.saving ? (
                                <i className="fas fa-spinner fa-pulse"></i>
                              ) : (
                                <>
                                  {/* {this.state.sensor.tagId ? {front} : <div><i className="fas fa-spinner fa-pulse"></i></div>}
                                   */}
                                  {this.state.currentPage === 1 &&
                                  this.state.sensor.tagId ? (
                                    "Process Configurations"
                                  ) : this.state.currentPage === 2 ? (
                                    "Firmware Configuration"
                                  ) : this.state.currentPage === 3 ? (
                                    "Process Configurations"
                                  ) : this.state.currentPage === 4 ? (
                                    "Mount Configuration"
                                  ) : this.state.currentPage === 5 ? (
                                    "Submit"
                                  ) : (
                                    <>
                                      <i className="fas fa-spinner fa-pulse"></i>
                                    </>
                                  )}
                                  <div className="icon custom-icon icon-shape bg-success text-white rounded-circle shadow">
                                    <i className="fa fa-forward" />
                                  </div>
                                </>
                              )}
                            </Button>
                          )}

                          {this.state.currentPage != 1 && (
                            <Button
                              className="right cmr-10"
                              color="danger"
                              onClick={() =>
                                this.handleCancel(
                                  this.state.pages[this.state.currentPage - 1]
                                )
                              }
                              size="md"
                            >
                              {
                                <div className="icon custom-icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fa fa-backward" />
                                </div>
                              }
                              {back}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  sensorTypeIdData: _.isEmpty(state.sensorTypes.sensorTypeId)
    ? null
    : state.sensorTypes.sensorTypeId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSensorTypeId: (sensorTypeId) => dispatch(setSensorTypeId(sensorTypeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SensorAdd);
