import { toast } from "react-toastify";

import { data } from "jquery";
import { isLiteralTypeNode } from "typescript";
import Helper from "./helper";

const axios = require("axios");

const clientIdData = {
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  category: "Portal",
  sub_category: "Organic",
};
let env = process.env.REACT_APP_BACKEND_SOURCE
  ? process.env.REACT_APP_BACKEND_SOURCE
  : "production";
console.log(env, "-------------ENV------------");

/**********************************
 * Base URL
 **********************************/

var baseUrl = "";
baseUrl = process.env.REACT_APP_BACKEND_SOURCE;
//Production
if (env === "production")
  if (env === "development")
    if (env === "local")
      baseUrl =
        // "http://private.dev2.nanoprecisedataservices.com:3077/forms/api/v3";
        // baseUrl = "http://dev.sensata.nanoprecisedataservices.com:3011/forms/api/v3";
        // baseUrl = "http://ec2-54-185-170-83.us-west-2.compute.amazonaws.com:30046/forms/api/v3"
        // baseUrl = "http://localhost:3000/forms/api/v3";
        // baseUrl = "http://52.27.171.24:3003/forms/api/v3"
        // baseUrl = "http://dev.nanoprecisedataservices.com:3017/forms/api/v3";
        // baseUrl = "https://portal.nanoprecisedataservices.com/forms/api/v3";
        //Development
        // baseUrl =
        //   "http://ec2-34-221-77-138.us-west-2.compute.amazonaws.com/forms/api/v3";

        //Localhost
        // baseUrl = 'http://localhost:3000/forms/api/v3';

        // baseUrl = "https://portal.nanoprecisedataservices.com/forms/api/v3";
        // baseUrl = "http://private.dev2.nanoprecisedataservices.com:3077/forms/api/v3";
        console.log("baseUrlbaseUrl: ", baseUrl);

let backend_dev_url = process.env.REACT_APP_BACKEND_URL;
let sns_dev_url = process.env.REACT_APP_NOTIFICATION_API_LINK;
let report_dev_url = process.env.REACT_APP_REPORT_API_LINK;
let login_url = process.env.REACT_APP_KEYCLOAK_API_LINK;
let sensorCoordinator_url = process.env.REACT_APP_SENSOR_COORDINATOR_API_LINK;

const logout = () => {
  Helper.clearAuth();
  Helper.deleteCookie();
  localStorage.removeItem("companyId");
  setTimeout(() => {
    window.location.href = "/auth/login/";
  }, 200);
};
const handleUnauthorize = async (err, instance) => {
  let originalConfig = err && err.config;
  if (
    err &&
    err.response &&
    err.response.status === 401 &&
    !originalConfig._retry
  ) {
    originalConfig._retry = true;
    let response = await axiosAuthInstance.post(
      `authentication/refresh_token/${Helper.getCookie("orgId")}/`,
      clientIdData
    );
    if (response && (response.status == 200 || response.status == 204)) {
      try {
        console.log("Refresh token fetched successfully");
        return await instance(originalConfig);
      } catch (e) {
        if (e.response && e.response.status == 401) {
          toast.warn("Session expired");
          logout();
        }
      }
    } else {
      toast.warn("Session expired");
      logout();
    }
  }
};
const NewAxios = (config) => {
  const instance = axios.default.create(config);

  instance.interceptors.request.use((config) => {
    const token = process.env.REACT_APP_CONFIG_TOKEN;
    config.headers.Authorization = token ? `Basic ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error: ", { error });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400 &&
        error.response.data.data &&
        error.response.data.status === "failed"
      ) {
        toast.error(error.response.data.data);
        return error;
      }
      //this will logout and redirect to login page
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 401
      ) {
        return handleUnauthorize(error, instance);
      }
      return error;
    }
  );

  const GET = instance.get;
  const POST = instance.post;
  const PUT = instance.put;
  const DELETE = instance.delete;

  return { GET, POST, PUT, DELETE };
};

const NewSNSAxios = (config) => {
  const instance = axios.default.create(config);

  instance.interceptors.request.use((config) => {
    const token = process.env.REACT_APP_NOTIFICATION_API_AUTH_TOKEN;
    config.headers.Authorization = token ? `Basic ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error: ", { error });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400 &&
        error.response.data.data &&
        error.response.data.status === "failed"
      ) {
        toast.error(error.response.data.data);
        return error;
      }
      //this will logout and redirect to login page
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 401
      ) {
        return handleUnauthorize(error, instance);
      }
      return error;
    }
  );

  const GET = instance.get;
  const POST = instance.post;
  const PUT = instance.put;
  const DELETE = instance.delete;

  return { GET, POST, PUT, DELETE };
};

const SensorAxios = (config) => {
  const instance = axios.default.create(config);

  instance.interceptors.request.use((config) => {
    console.log("REACT_APP_SENSOR_TOKEN", process.env.REACT_APP_SENSOR_TOKEN);
    const token = process.env.REACT_APP_SENSOR_TOKEN;
    config.headers.Authorization = token ? `Basic ${token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error: ", { error });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400 &&
        error.response.data.data &&
        error.response.data.status === "failed"
      ) {
        toast.error(error.response.data.data);
      }
      return handleUnauthorize(error, instance);
      // return error.response;
    }
  );

  const GET = instance.get;
  const POST = instance.post;
  const PUT = instance.put;
  const DELETE = instance.delete;

  return { GET, POST, PUT, DELETE };
};
const kms_Axios = (config) => {
  const instance = axios.default.create(config);

  instance.interceptors.request.use((config) => {
    let kms_token = process.env.REACT_APP_KEYCLOAK_TOKEN;
    config.headers.Authorization = kms_token ? `Basic ${kms_token}` : "";
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("error: ", { error });
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.status === 400 &&
        error.response.data.data &&
        error.response.data.status === "failed"
      ) {
        toast.error(error.response.data.data);
      }
      return handleUnauthorize(error, instance);
      // return error.response;
    }
  );

  const GET = instance.get;
  const POST = instance.post;
  const PUT = instance.put;
  const DELETE = instance.delete;

  return { GET, POST, PUT, DELETE };
};

const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_API_LINK,
  headers: { Authorization: `Basic ${process.env.REACT_APP_KEYCLOAK_TOKEN}` },
  withCredentials: true,
});

axiosAuthInstance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosAuthInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.status === 400 &&
      error.response.data.data &&
      error.response.data.status === "failed"
    ) {
      toast.error(error.response.data.data);
    }
    return handleUnauthorize(error, axiosAuthInstance);
    // return console.log("error", error);
    //  return handleUnathorise(error, axiosAuthInstance);
  }
);

///CONFIGAPI INSTANCE Instance
export const axiosConfigInstance = axios.create({
  baseURL: backend_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${process.env.REACT_APP_CONFIG_TOKEN}`,
  },
});

axiosConfigInstance.interceptors.request.use(
  function (request) {
    return request;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosConfigInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return handleUnauthorize(error, axiosConfigInstance);
    // return handleError(error, axiosConfigInstance);
  }
);

const newAPI = NewAxios({
  baseURL: backend_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_CONFIG_TOKEN,
  },
});

const devApi = NewAxios({
  baseURL: backend_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_CONFIG_TOKEN,
  },
});

const devnApi = NewAxios({
  baseURL: report_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_CONFIG_TOKEN,
  },
});

const configApi = NewAxios({
  baseURL: backend_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_CONFIG_TOKEN,
  },
});

const snsApi = NewSNSAxios({
  baseURL: sns_dev_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_NOTIFICATION_API_AUTH_TOKEN
  },
});

const loginApi = kms_Axios({
  baseURL: login_url,
  headers: {
    "Content-Type": "application/json",
  },
});

const loginUserInfo = kms_Axios({
  baseURL: login_url,
  headers: {
    "Content-Type": "application/json",
  },
});

const sensorCoordinator = NewAxios({
  baseURL: sensorCoordinator_url,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic " + process.env.REACT_APP_CONFIG_TOKEN,
  },
});

const responseHandler = (response, defaultValue) => {
  if (!response) return defaultValue;

  if (response.status === 200) return response.data.data || defaultValue;
  return defaultValue;
};

const http = {
  postReq(url, data) {
    return axios({
      header: {
        "x-auth-token": localStorage.getItem("portal-user-access-token"),
      },
      method: "post",
      url: url,
      data: data,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  putReq(url, data) {
    return axios({
      header: {
        "x-auth-token": localStorage.getItem("portal-user-access-token"),
      },
      method: "put",
      url: url,
      data: data,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  getReq(url) {
    return axios({
      method: "get",
      url: url,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  deleteReq(url) {
    return axios({
      method: "delete",
      url: baseUrl + url,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },
  deleteReqNotification(url) {
    return axios({
      method: "delete",
      url: url,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  login(email, password) {
    let url = baseUrl + "/auth";
    let body = {
      email: email,
      password: password,
    };

    return this.postReq(url, body);
  },

  getOrganizations(organizationId = null) {
    let url = baseUrl + "/organization";
    if (organizationId) {
      url += "/" + organizationId;
    }

    return this.getReq(url);
  },

  saveCompanyThemeConfig(body) {
    let url = baseUrl + "/companies/theme";
    return this.postReq(url, body);
  },

  saveCompanyLoginThemeConfig(body) {
    let url = baseUrl + "/companies/login-theme";
    return this.postReq(url, body);
  },

  addComponent() {
    let url = baseUrl + "/components";
    let ed_links = ["link1ssdsdsd", "link2jjjkjk"];

    let body = {
      ed_links: JSON.stringify(ed_links),
    };
    return this.postReq(url, body);
  },

  getEquipmentTypesMeta() {
    let url = baseUrl + "/equipment-types/meta";
    return this.getReq(url);
  },

  notificationAlert(
    endpointDetails,
    notifierId,
    companyId,
    organizationId,
    tagId,
    subject,
    frequency,
    timestamp,
    source,
    code,
    isNotificationApproved
  ) {
    let url = endpointDetails;

    let body = {
      notifierId: notifierId,
      companyId: companyId,
      organizationId: organizationId,
      tagId: tagId,
      subject: subject,
      frequency: frequency,
      timestamp: timestamp,
      source: source,
      code: code,
      isNotificationApproved: isNotificationApproved,
    };

    return this.postReq(url, body);
  },
  // getEquipmentTypes(companyId, equipmentTypeCode=null) {
  //     let url = baseUrl + '/equipments/types/' + companyId;
  //     if (equipmentTypeCode) {
  //         url += '/' + equipmentTypeCode
  //     }
  //     return this.getReq(url);
  // },

  saveEquipmentType(equipmentType, id = null) {
    let url = "";
    if (id) {
      url = baseUrl + "/equipments/types/" + id;
      return this.putReq(url, equipmentType);
    } else {
      url = baseUrl + "/equipments/types";
      return this.postReq(url, equipmentType);
    }
  },
  saveEquipmentSubType(equipmentType, id = null) {
    let url = "";
    if (id) {
      url = baseUrl + "/equipments/subtypes/" + id;
      return this.putReq(url, equipmentType);
    } else {
      url = baseUrl + "/equipments/subtypes";
      return this.postReq(url, equipmentType);
    }
  },

  getComponentTypesMeta() {
    let url = baseUrl + "/component-types/meta";
    return this.getReq(url);
  },

  saveComponentType(componentType, id = null) {
    let url = "";
    if (id) {
      url = baseUrl + "/components/types/" + id;
      return this.putReq(url, componentType);
    } else {
      url = baseUrl + "/components/types";
      return this.postReq(url, componentType);
    }
  },

  getData(endpoint) {
    let url = baseUrl + endpoint;
    return this.getReq(url);
  },

  createNotificationGroup(endpoint, body) {
    let url = baseUrl + endpoint;
    return this.postReq(url, body);
  },

  deleteNotificationGroup(endpoint, body) {
    let url = baseUrl + endpoint;
    return axios({
      method: "delete",
      url: url,
      data: body,
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
  },

  addRPMRatio(body) {
    let url = baseUrl + "/components/rpmRatio";
    return this.postReq(url, body);
  },
  addRPMType(body) {
    let url = baseUrl + "/equipments/saveRPMType";
    return this.postReq(url, body);
  },
  getNotification(endpoint) {
    let url = baseUrl + endpoint;
    return this.getReq(url);
  },

  getNotificationUpdate(endpoint, isNotificationReviewed, name) {
    let url = baseUrl + endpoint;
    let body = {
      isNotificationReviewed: isNotificationReviewed,
      reviewer: name,
    };
    return this.putReq(url, body);
  },

  getNotificationUpdateApproved(endpoint, isNotificationApproved, name) {
    let url = baseUrl + endpoint;
    let body = {
      isNotificationApproved: isNotificationApproved,
      approver: name,
    };
    return this.putReq(url, body);
  },

  NotificationUpdateSuggestion(endpoint, suggestedAction) {
    let url = baseUrl + endpoint;
    let body = {
      suggestedAction: suggestedAction,
    };
    return this.putReq(url, body);
  },

  notificationDelete(endpoint) {
    let url = baseUrl + endpoint;
    console.log(url);

    return this.deleteReqNotification(url);
  },

  addNotification(
    endpoint,
    notifierId,
    companyId,
    organizationId,
    tagId,
    sensorId,
    defaultmessage,
    sms,
    email,
    subject,
    frequency,
    timestamp,
    source,
    code,
    isNotificationApproved,
    isNotificationReviewed,
    reviewer,
    approver,
    disapprover,
    comments,
    sent,
    updatedTimeStamp,
    suggestedAction
  ) {
    let url = baseUrl + endpoint;
    let body = {
      notifierId: notifierId,
      organizationId: organizationId,
      tagId: tagId,
      sensorId: sensorId,
      message: {
        default: defaultmessage,
        sms: sms,
        email: email,
      },
      subject: subject,
      frequency: frequency,
      timestamp: timestamp,
      source: source,
      code: code,
      isNotificationApproved: isNotificationApproved,
      isNotificationReviewed: isNotificationReviewed,
      reviewer: reviewer,
      approver: approver,
      disapprover: disapprover,
      comments: comments,
      sent: sent,
      updatedTimeStamp: updatedTimeStamp,
      suggestedAction: suggestedAction,
    };
    return this.postReq(url, body);
  },

  postNotificationUpdate(endpoint, isNotificationReviewed, name) {
    let url = baseUrl + endpoint;
    let body = {
      isNotificationReviewed: isNotificationReviewed,
      reviewer: name,
    };
    return this.putReq(url, body);
  },

  updateCompany(company, id) {
    const url = baseUrl + "/companies/" + id;
    return this.putReq(url, company);
  },

  updateData(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.putReq(url, data);
  },
  postNotificationMute(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },
  postRPMRatio(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },
  postDelRPMRatio(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },
  postData(endpoint, data, id = null) {
    let url = "";
    if (id) {
      url = baseUrl + endpoint + "/" + id;
      return this.putReq(url, data);
    } else {
      url = baseUrl + endpoint;
      return this.postReq(url, data);
    }
  },
  postBulkSensorInsert(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },
  saveDashboardDetails(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },
  saveFeedback(endpoint, data) {
    const url = baseUrl + endpoint;
    return this.postReq(url, data);
  },

  // ===
  // New API's
  // ===

  // ===
  responseHandler,

  // Login
  fetchLogin: (id) => devApi.GET(`assetmanagement/organization/name/${id}`),
  //post_organizationLogin: (organizationId, data) => loginApi.POST(`authentication/${organizationId}/`, data),
  post_organizationLogin: (organizationId, data) =>
    axiosAuthInstance.post(`authentication/${organizationId}/`, data),
  fetchUserInfo: (organizationId) =>
    axiosAuthInstance.get(`get_user_details/${organizationId}/`),
  fetchForgotPassword: (organizationId, data) =>
    loginApi.POST(`user/forgot_password/${organizationId}/`, data),
  fetchRefreshToken: (organizationId) =>
    axiosAuthInstance.post(
      `authentication/refresh_token/${organizationId}/`,
      clientIdData
    ),
  // // Report Download
  // fetchReport: (startDate, endDate) => reportApi.GET(`/metrics/download?start=${startDate}&end=${endDate}`),

  fetchMasterUser: (organization_id) =>
    axiosAuthInstance.get(
      `admin/get_all_users_organization/${organization_id}/`
    ),
  //theme
  company_theme: (companyId) =>
    devApi.GET(`/assetmanagement/company/theme/${companyId}/`),
  post_CompanyTheme: (data) =>
    devApi.POST(`/assetmanagement/company/theme/`, data),
  update_CompanyTheme: (companyId, data) =>
    devApi.PUT(`/assetmanagement/company/theme/${companyId}/`, data),

  //Login Theme
  company_loginTheme: (domainName) =>
    devApi.GET(`/assetmanagement/company/login-theme/${domainName}/`),
  post_CompanyLoginTheme: (data) =>
    devApi.POST(`/assetmanagement/company/login-theme/`, data),
  update_CompanyLoginTheme: (domainName, data) =>
    devApi.PUT(`/assetmanagement/company/login-theme/${domainName}/`, data),

  // Company Management
  companyManage_CompanyList: () => newAPI.GET(`/assetmanagement/company/list`),

  companyManage_CompanyDetails: (companyId) =>
    newAPI.GET(`/assetmanagement/company/${companyId}/info`),
  companyManage_create: (data) => newAPI.POST(`/assetmanagement/company`, data),
  companyManage_update: (id, data) =>
    newAPI.PUT(`/assetmanagement/company/${id}`, data),
  companyManage_Delete: (id) => newAPI.DELETE(`/assetmanagement/company/${id}`),

  //Plant Management
  plantManage_plantList: (companyId) =>
    newAPI.GET(`/assetmanagement/company/${companyId}/list`),
  plantManage_plantDetails: (id) =>
    newAPI.GET(`/assetmanagement/plant/${id}/info`),
  plantManage_create: (data) => newAPI.POST(`/assetmanagement/plant`, data),
  plantManage_update: (plantId, data) =>
    newAPI.PUT(`/assetmanagement/plant/${plantId}`, data),
  plantManage_Delete: (plantId) =>
    newAPI.DELETE(`/assetmanagement/plant/${plantId}`),

  //get company data count
  getAssetCount: (companyId) =>
    devApi.GET(`/assetmanagement/company-stats?company_id=${companyId}`),

  //EULA
  getEula: () => devApi.GET(`/assetmanagement/eula/`),
  getEula_byId: (id) => devApi.GET(`/assetmanagement/eula/${id}/`),
  post_eula: (data) => devApi.POST(`/assetmanagement/eula/`, data),
  update_eula: (id, data) => devApi.PUT(`/assetmanagement/eula/${id}/`, data),
  delete_eula: (id) => devApi.DELETE(`/assetmanagement/eula/${id}/`),

  //Dashboard-manuals
  getDashboardManual: () => devApi.GET(`/assetmanagement/dashboard-manuals/`),
  getDashboardManual_byId: (id) =>
    devApi.GET(`/assetmanagement/dashboard-manuals/${id}/`),
  post_DashboardManual: (data) =>
    devApi.POST(`/assetmanagement/dashboard-manuals/`, data),
  update_DashboardManual: (id, data) =>
    devApi.PUT(`/assetmanagement/dashboard-manuals/${id}/`, data),
  delete_DashboardManual: (id) =>
    devApi.DELETE(`/assetmanagement/dashboard-manuals/${id}/`),

  //Sensor Types
  getSensorTypes: () => devApi.GET(`/sensortype/list`),
  getSensorType_byId: (id) => devApi.GET(`/sensortype/details/${id}`),
  post_SensorType: (data) => devApi.POST(`/sensortype/add`, data),
  update_SensorType: (data) => devApi.PUT(`/sensortype/update`, data),
  delete_SensorType: (id) => devApi.DELETE(`/sensortype/delete/${id}`),

  //Firmware Types
  getFirmwareTypes: () => devApi.GET(`/firmwaretype/list`),
  getFirmwareType_byId: (id) => devApi.GET(`/firmwaretype/details/${id}`),
  post_FirmwareType: (data) => devApi.POST(`/firmwaretype/add`, data),
  update_FirmwareType: (data) => devApi.PUT(`/firmwaretype/update`, data),
  delete_FirmwareType: (id) => devApi.DELETE(`/firmwaretype/delete/${id}`),

  //DelayedCDP
  getDelayedCDP_list: () => devApi.GET(`/delayed-cdp/list`),
  delayProcessTags: (data) => devApi.POST(`/delayed-cdp/reprocess`, data),

  //Machine Management
  machineManage_machineList: (companyId) =>
    newAPI.GET(`/assetmanagement/machine/companyId/${companyId}/list`),
  machineManage_plantMachineList: (plantId) =>
    newAPI.GET(`/assetmanagement/plant/${plantId}/list`),
  machineManage_machineDetails: (machineId) =>
    newAPI.GET(`/assetmanagement/machine/${machineId}/info`),
  machineManage_create: (data) => newAPI.POST(`/assetmanagement/machine`, data),
  machineManage_update: (id, data) =>
    newAPI.PUT(`/assetmanagement/machine/${id}`, data),
  machineManage_delete: (id) => newAPI.DELETE(`/assetmanagement/machine/${id}`),

  //Equipment Management
  equipmentManage_equipmentList: (companyId) =>
    newAPI.GET(`/assetmanagement/equipment/companyId/${companyId}/list`),
  equipmentManage_equipmentListMachine: (machineId) =>
    newAPI.GET(`/assetmanagement/machine/${machineId}/list`),
  equipmentManage_equipmentDetails: (id) =>
    newAPI.GET(`/assetmanagement/equipment/${id}/info`),
  equipmentManage_create: (data) =>
    newAPI.POST(`/assetmanagement/equipment`, data),
  equipmentManage_update: (id, data) =>
    newAPI.PUT(`/assetmanagement/equipment/${id}`, data),
  equipmentManage_delete: (id) =>
    newAPI.DELETE(`/assetmanagement/equipment/${id}`),

  //RPM ratio
  rpmRatio_get: (id) =>
    newAPI.GET(`/assetmanagement/rpmratio/${id && id.equipmentId}/info`),

  rpmRatio_create: (data) => newAPI.POST(`/assetmanagement/rpmratio`, data),
  rpmRatio_update: (data) => newAPI.PUT(`/assetmanagement/rpmratio`, data),
  rpmRatio_delete: (tagId, linkedTagId) =>
    newAPI.DELETE(`/assetmanagement/rpmratio/${tagId}_${linkedTagId}`),

  //Bearing
  bearing_list: (searchQuery) =>
    newAPI.GET(`/configmanagement/bearing/list?search=${searchQuery}`),
  bearing_details: (id) => newAPI.GET(`/configmanagement/bearing/${id}`),

  //Mount Config
  mountConfig_List: () => newAPI.GET(`/configmanagement/mounttype/list`),
  mountConfig_details: (id) => newAPI.GET(`/configmanagement/mounttype/${id}`),
  mountConfig_create: (data) =>
    newAPI.POST(`/configmanagement/mounttype`, data),
  mountConfig_update: (id, data) =>
    newAPI.PUT(`/configmanagement/mounttype/${id}`, data),
  mountConfig_delete: (id) =>
    newAPI.DELETE(`/configmanagement/mounttype/${id}`),

  //Old Config New Config
  configTypeDetails: (tagId) => newAPI.GET(`/configmanagement/config/${tagId}`),
  //Equipment config
  equipConfig_list: (companyId) =>
    newAPI.GET(`/configmanagement/equipment/list/${companyId}`),
  equipConfig_details: (id) => newAPI.GET(`/configmanagement/equipment/${id}`),
  equipConfig_create: (data) =>
    newAPI.POST(`/configmanagement/equipment`, data),
  equipConfig_update: (id, data) =>
    newAPI.PUT(`/configmanagement/equipment/${id}`, data),
  equipConfig_delete: (id) =>
    newAPI.DELETE(`/configmanagement/equipment/${id}`),

  //Component config
  componentConfig_list: (companyId) =>
    newAPI.GET(`/configmanagement/component/list/${companyId}`),
  componentConfig_details: (id) =>
    newAPI.GET(`/configmanagement/component/${id}`),
  componentConfig_create: (data) =>
    newAPI.POST(`/configmanagement/component`, data),
  componentConfig_update: (id, data) =>
    newAPI.PUT(`/configmanagement/component/${id}`, data),
  componentConfig_delete: (id) =>
    newAPI.DELETE(`/configmanagement/component/${id}`),
  componentConfig_updateOld: (id, data) =>
    newAPI.PUT(`/configmanagement/oldcomponent/${id}`, data),
  componentConfig_deleteOld: (id) =>
    newAPI.DELETE(`/configmanagement/oldcomponent/${id}`),

  //Process Config used on process config sideBar
  get_Process_config: (id) => newAPI.GET(`/configmanagement/oldcomponent/none`),

  get_orgLogo: (fileName) =>
    devApi.GET(`/assetmanagement/file/logo/${fileName}`),
  update_orgLogo: (fileName, data) =>
    devApi.PUT(`/assetmanagement/file/logo/${fileName}`, data),
  add_orgLogo: (data) => devApi.POST(`/assetmanagement/file/logo`, data),
  delete_orgLogo: (fileName) =>
    devApi.DELETE(`/assetmanagement/file/logo/${fileName}`),

  //component type
  componentType_get: () => newAPI.GET(`/configmanagement/componentType/info`),
  //equipment type
  equipmentType_get: () => newAPI.GET(`/configmanagement/equipmentType/info`),

  //Company Config
  companyConfig_list: (companyId) =>
    newAPI.GET(`/configmanagement/company/${companyId}`),
  companyConfig_create: (data) =>
    newAPI.POST(`/configmanagement/company`, data),
  companyConfig_update: (id, data) =>
    newAPI.PUT(`/assetmanagement/company/${id}`, data),
  companyConfig_deleteCompany: (id) =>
    newAPI.DELETE(`/configmanagement/company/${id}`),

  companyConfig_all: (data) =>
    devApi.POST(`/configmanagement/companyconfig/all`, data),
  companyConfig_lang: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/lang`, data),
  companyConfig_export: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/export`, data),
  companyConfig_notification: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/notification`, data),
  companyConfig_idletime: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/idletime`, data),
  companyConfig_dailyalarm: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/dailyalarm`, data),
  companyConfig_threshold: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/threshold`, data),
  companyConfig_sensorstat: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/sensorstat`, data),
  companyConfig_timezone: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/timezone`, data),
  companyConfig_unit: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/unit`, data),
  companyConfig_cdpsessions: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/cdpsessions`, data),
  companyConfig_bool: (data) =>
    newAPI.POST(`/configmanagement/companyconfig/bool`, data),

  companyConfig_allUpdate: (data, id) =>
    devApi.PUT(`/configmanagement/companyconfig/all/${id}`, data),
  companyConfig_langUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/lang/${id}`, data),
  companyConfig_exportUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/export/${id}`, data),
  companyConfig_notificationUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/notification/${id}`, data),
  companyConfig_idletimeUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/idletime/${id}`, data),
  companyConfig_dailyalarmUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/dailyalarm/${id}`, data),
  companyConfig_thresholdUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/threshold/${id}`, data),
  companyConfig_sensorstatUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/sensorstat/${id}`, data),
  companyConfig_timezoneUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/timezone/${id}`, data),
  companyConfig_unitUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/unit/${id}`, data),
  companyConfig_cdpsessionsUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/cdpsessions/${id}`, data),
  companyConfig_boolUpdate: (data, id) =>
    newAPI.PUT(`/configmanagement/companyconfig/bool/${id}`, data),

  companyConfig_details: (id) =>
    newAPI.GET(`/configmanagement/companyconfig/${id}`),
  companyConfig_delete: (id) =>
    newAPI.DELETE(`/configmanagement/companyconfig/${id}`),

  //trtagsmaps
  trtagmapsview_all: () =>
    newAPI.GET(`/configmanagement/all/trtagmapsview/none`),
  trtagmapsview_company: (id) =>
    newAPI.GET(`/configmanagement/company/trtagmapsview/${id}`),
  trtagmapsview_plant: (id) =>
    newAPI.GET(`/configmanagement/plant/trtagmapsview/${id}`),
  trtagmapsview_machine: (id) =>
    newAPI.GET(`/configmanagement/machine/trtagmapsview/${id}`),
  trtagmapsview_equipment: (id) =>
    newAPI.GET(`/configmanagement/equipment/trtagmapsview/${id}`),
  trtagmapsview_component: (id) =>
    newAPI.GET(`/configmanagement/component/trtagmapsview/${id}`),
  trtagmapsview_tagId: (id) =>
    newAPI.GET(`/configmanagement/tagId/trtagmapsview/${id}`),

  //File Data image
  fileDataImage_get: (fileName, companyId) =>
    newAPI.GET(`/assetmanagement/file/image/${fileName}/${companyId}`),
  fileDataImage_create: (data) =>
    newAPI.POST(`/assetmanagement/file/image`, data),
  fileDataImage_update: (fileName, data) =>
    newAPI.PUT(`/assetmanagement/file/image/${fileName}`, data),
  fileDataImage_delete: (fileName, companyId) =>
    newAPI.DELETE(`/assetmanagement/file/image/${fileName}/${companyId}`),

  //File data logo
  fileDataLogo_get: (fileName, companyId) =>
    newAPI.GET(`/assetmanagement/file/logo/${fileName}/${companyId}`),
  fileDataLogo_create: (data) =>
    newAPI.POST(`/assetmanagement/file/logo`, data),
  fileDataLogo_update: (fileName, data) =>
    newAPI.PUT(`/assetmanagement/file/logo/${fileName}`, data),
  fileDataLogo_delete: (fileName, companyId) =>
    newAPI.DELETE(`/assetmanagement/file/logo/${fileName}/${companyId}`),

  //File data report
  fileDataReport_get: (fileName, companyId) =>
    newAPI.GET(`/assetmanagement/file/report/${fileName}/${companyId}`),
  fileDataReport_create: (data) =>
    newAPI.POST(`/assetmanagement/file/report`, data),
  fileDataReport_update: (fileName, data) =>
    newAPI.PUT(`/assetmanagement/file/report/${fileName}`, data),
  fileDataReport_delete: (fileName, companyId) =>
    newAPI.DELETE(`/assetmanagement/file/report/${fileName}/${companyId}`),

  // Sensor Management
  sensorManage_UnassignedList: (companyId) =>
    newAPI.GET(`/sensormanagement/unassigned/list/${companyId}`),
  sensorManage_AssignedList: (companyId) =>
    newAPI.GET(`/sensorassignment/list/${companyId}`),
  sensorManage_AllSensorList: (companyId) =>
    newAPI.GET(`/sensormanagement/all/list/${companyId}`),

  sensorManage_AssignedDetails: (sensorId) =>
    newAPI.GET(`/sensorassignment/${sensorId}`),

  sensorManage_Details: (sensorId) =>
    newAPI.GET(`/sensormanagement/${sensorId}`),

  sensorManage_Create: (data) => newAPI.POST(`/sensormanagement`, data),
  sensorManage_Update: (sensorId, data) =>
    newAPI.PUT(`/sensormanagement/${sensorId}`, data),
  sensorManage_Delete: (sensorId) =>
    newAPI.DELETE(`/sensormanagement/${sensorId}`),

  sensorManage_Assign: (data) => newAPI.POST(`/sensorassignment`, data),
  sensorManage_Unassign: (sensorId) =>
    newAPI.DELETE(`/sensorassignment/${sensorId}`),
  sensorManage_Reassign: (sensorId, data) =>
    newAPI.PUT(`/sensorassignment/${sensorId}`, data),

  sensorManage_ProcessConfig: (data) =>
    newAPI.POST(`/configmanagement/oldcomponent`, data),
  sensorManage_ProcessConfigGetData: (id) =>
    newAPI.GET(`/configmanagement/oldcomponent/${id}`),

  sensorManage_ProcessConfigUpdate: (tagId, data) =>
    newAPI.PUT(`/configmanagement/oldcomponent/${tagId}`, data),

  // Asset Management
  assetManage_ComponentList: (companyId) =>
    newAPI.GET(`/assetmanagement/component/companyId/${companyId}/list`),
  assetManage_ComponentListOfEquipment: (equipmentId) =>
    newAPI.GET(`/assetmanagement/equipment/${equipmentId}/list`),
  assetManage_componentDetails: (componentId) =>
    newAPI.GET(`/assetmanagement/component/${componentId}/info`),
  assetManage_ComponentCreate: (data) =>
    newAPI.POST(`/assetmanagement/component`, data),
  assetManage_ComponentUpdate: (componentId, data) =>
    newAPI.PUT(`/assetmanagement/component/${componentId}`, data),
  assetManage_ComponentDelete: (componentId) =>
    newAPI.DELETE(`/assetmanagement/component/${componentId}`),

  //Organization Management
  organization_List: () =>
    devApi.GET(
      `/assetmanagement/organization-list?projection={"customName":1,"organizationId":1}`
    ),
  organization_Details: (organizationId) =>
    devApi.GET(`/assetmanagement/organization/${organizationId}/info`),

  organization_Create: (data) =>
    devApi.POST(`/assetmanagement/organization`, data),

  organization_Update: (organizationId, data) =>
    devApi.PUT(`/assetmanagement/organization/${organizationId}`, data),

  organization_Delete: (id) =>
    devApi.DELETE(`/assetmanagement/organization/${id}`),

  organization_companyList: (id) =>
    devApi.GET(
      `/assetmanagement/company-list?org_ids=${id}&projection={"customName":1,"companyId":1}`
    ),

  organization_groupList: (companyId) =>
    devApi.GET(
      `/assetmanagement/plant-list?company_ids=${companyId}&projection={"customName":1,"plantId":1}`
    ),

  organization_machineList: () =>
    devApi.GET(
      `/assetmanagement/machine-list?plant_ids=100000000278,20&projection={"customName":1,"machineId":1}`
    ),

  organization_equipmentList_byMachineId: () =>
    devApi.GET(
      `/assetmanagement/equipment-list?machine_ids=200000000077,200000000078&projection={"customName":1,"equipmentId":1}`
    ),

  organization_equipmentList_byPlantId: () =>
    devApi.GET(
      `/assetmanagement/equipment-list?plant_ids=100000000278,20&projection={"customName":1,"equipmentId":1}`
    ),

  organization_componentList: () =>
    devApi.GET(
      `/assetmanagement/component-list?equipment_ids=1200,1201&projection={"customName":1,"componentId":1}`
    ),

  organizationConfig_details: (id) =>
    devApi.GET(`/configmanagement/organizationconfig/${id}`),

  organizationConfig_details_list: () =>
    devApi.GET(`/configmanagement/organizationconfig/list`),

  organizationConfig_update: (id, data) =>
    devApi.PUT(`/configmanagement/organizationconfig/${id}/`, data),

  organizationConfig_deleteOrganization: (id) =>
    devApi.DELETE(`/configmanagement/organizationconfig/${id}`),

  organizationConfig_create: (data) =>
    devApi.POST(`/configmanagement/organizationconfig/`, data),

  organization_stats_data: () =>
    devApi.GET(`/assetmanagement/organization-stats`),

  //FeedbackLog
  get_feedBack: (companyId, archivedBool, endTime, startTime) => {
    let url = `/portal/feedbacklog/${companyId}/${archivedBool}?end_time=${endTime}&start_time=${startTime}`;

    // if (startTime) {
    //   url += `&start_time=${startTime}`;
    // }

    return snsApi.GET(url);
  },
  post_feedBack_noAction: (data) =>
    snsApi.POST(`/portal/feedbacklog/noAction`, data),
  post_feedBack_faultDetection: (data) =>
    snsApi.POST(`/portal/feedbacklog/resetFaultDetection/update`, data),
  post_feedBack_maintenanceWatch: (data) =>
    snsApi.POST(`/portal/feedbacklog/maintenanceWatch`, data),

  //Maintenance Type
  get_maintenanceType: (companyId) =>
    snsApi.GET(`/maintenance-types?companyId=${companyId}`),
  post_feedBack_maintenanceTypesAdd: (companyId, data) =>
    snsApi.POST(
      `/portal/feedbacklog/maintenanceTypes/maintenanceTypesAdd/${companyId}`,
      data
    ),
  del_feedBack_maintenanceTypesDelete: (companyId, data) =>
    snsApi.POST(
      `/portal/feedbacklog/maintenanceTypes/maintenanceTypesDel/${companyId}`,
      data
    ),
  feedBack_maintenanceTypesUpdate: (companyId, data) =>
    snsApi.POST(
      `/portal/feedbacklog/maintenanceTypes/maintenanceTypes/${companyId}`,
      data
    ),

  feedBack_maintenanceWatch: (data) =>
    snsApi.POST(`/portal/feedbacklog/maintenanceWatchEquipment`, data),
  feedBack_resetFaultDetection: (data) =>
    snsApi.POST(`/portal/feedbacklog/resetFaultDetectionEquipment`, data),
  feedBack_syncUploadTime: (data) =>
    configApi.POST(`/portal/feedbacklog/syncUPloadTime`, data),

  getNoticationAlertData: (companyId, status, startDate, endDate) =>
    snsApi.GET(
      `/portal/notificationAlertData/${companyId}/${status}/${startDate}/${endDate}`
    ),
  getAllNotificationData: (companyId, status, startDate, endDate, email) =>
    snsApi.GET(
      `/portal/notificationAlertData/${companyId}/${status}/${startDate}/${endDate}?send_as_email=True&receiver_email=${email}`
    ),

  get_component: (componentId) =>
    devApi.GET(`/assetmanagement/component/${componentId}/info`),

  get_Manual: (type) => devApi.GET(`/utilities/manual?resource=${type}`),

  Post_Manual: (type, data) =>
    devApi.POST(`/assetmanagement/${type}/update`, data),

  Post_NotificationManual: (data) =>
    devApi.POST(`/assetmanagement/dashboard-manuals/`, data),

  //notification_codes
  get_notificationCodes: (roleId) =>
    snsApi.GET(`/notification-types?type=${roleId}`),

  fetchReport: (startDate, endDate) =>
    devnApi.GET(`/metrics/download?start=${startDate}&end=${endDate}`),

  //sensor coordinate
  put_sensorCoordinator: (data) =>
    sensorCoordinator.POST(`/update/sensor_details/`, data),

  post_emailConfigComp: (data) =>
    devApi.POST(`/configmanagement/companyconfig/all`, data),
  put_emailConfigComp: (companyId, data) =>
    devApi.PUT(`/configmanagement/companyconfig/smtp/${companyId}`, data),

  post_emailConfigOrg: (data) =>
    devApi.POST(`/configmanagement/organizationconfig/`, data),
  put_emailConfigOrg: (organizationId, data) =>
    devApi.PUT(`/configmanagement/organizationconfig/${organizationId}/`, data),
};

export default http;
